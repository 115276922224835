import '../css/styles.css'

import '@splidejs/splide/css'

import Splide from '@splidejs/splide'
import { Intersection } from '@splidejs/splide-extension-intersection'

import Ukiyo from 'ukiyojs'

import { gsap } from 'gsap'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

import 'budoux/module/webcomponents/budoux-ja'

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.create({
  trigger: '.block-about',
  start: 'top bottom',
  end: 'top 2.5%',
  onUpdate: (self) => {
    const propgRaw = self.progress.toFixed(3)
    const prog = (2 * propgRaw - 1) ** 2

    document.documentElement.style.setProperty('--about-opacity', prog)
  },
})

const MenuBlocks = [...document.querySelectorAll('.menu-block')]

MenuBlocks.forEach((el) => {
  ScrollTrigger.create({
    trigger: el,
    start: '37.5% bottom',
    end: '25%',
    onUpdate: (self) => {
      const propgRaw = self.progress.toFixed(3)
      const prog = (2 * propgRaw - 1) ** 2

      el.style.setProperty('--menu-block-opacity', prog)
    },
  })
})

if (window.matchMedia('(max-width: 768px)').matches) {
  if (document.querySelector('.follow-me')) {
    const follow_me_stop =
      document.querySelector('.block-shop-info').offsetHeight +
      document.querySelector('.footer-notice').offsetHeight +
      document.querySelector('.footer').offsetHeight -
      document.querySelector('.follow-me').offsetHeight -
      16

    ScrollTrigger.create({
      trigger: '.block-shop-info',
      start: 'top bottom',
      onToggle: (self) => {
        if (self.isActive) {
          document
            .querySelector('.follow-me')
            .style.setProperty('--follow-me-stop', follow_me_stop + 'px')
          document.querySelector('.follow-me').classList.add('position-on-footer')
        } else {
          document.querySelector('.follow-me').classList.remove('position-on-footer')
        }
      },
    })
  }
}

const splide_elms = [...document.querySelectorAll('#menu .splide')]

splide_elms.forEach((el) => {
  const splide = new Splide(el, {
    type: 'loop',
    focus: 'center',
    gap: '-16.25%',
    pagination: false,
    autoplay: true,
    autoplaySpeed: 10000,
    updateOnMove: true,
    autoWidth: true,
    speed: 180,
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: true,
      },
    },
    breakpoints: {
      960: {
        perPage: 1,
        gap: 20,
        pagination: true,
        autoWidth: false,
      },
      1536: {
        gap: 'min(-16.25%,-310px)',
      },
    },
  }).mount({
    Intersection,
  })

  const slides = [...el.querySelectorAll('.splide__slide')]

  splide.on('move', () => {
    slides.map((el) => el.classList.add('moving'))
  })

  splide.on('moved', (i, p, _d) => {
    slides.map((el) => el.classList.remove('moving'))
  })
})

if (document.querySelector('#quality .splide')) {
  new Splide('#quality .splide', {
    type: 'loop',
    focus: 'center',
    perPage: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 10000,
    gap: 20,
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: true,
      },
    },
  }).mount({
    Intersection,
  })
}

let ukiyoConfig = {
  scale: 2,
  speed: 2,
}

if (window.matchMedia('(max-width: 768px)').matches) {
  ukiyoConfig = {
    scale: 1.35,
    speed: 1.35,
  }
}

new Ukiyo('.parallax-image', ukiyoConfig)

const blockKvHeiht = document.querySelector('.block-kv')?.offsetHeight

if (blockKvHeiht) {
  document.documentElement.style.setProperty('--block-kv-heiht', blockKvHeiht + 'px')
}

$(function () {
  $('.js-top-slide').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
  })

  $('.js-slide-quality').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 6000,
    arrows: true,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '30%',
    slidesToShow: 1,
  })

  $('.js-slide-moose').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    cssEase: 'ease',
    arrows: true,
    pauseOnHover: false,
  })

  $('.js-slide-drink').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    cssEase: 'ease',
    arrows: true,
    pauseOnHover: false,
    centerPadding: '50%',
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: 0,
        },
      },
    ],
  })

  $('.js-slide-parfait').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    cssEase: 'ease',
    arrows: true,
    pauseOnHover: false,
  })

  $('.js-slide-soft').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    cssEase: 'ease',
    arrows: true,
    pauseOnHover: false,
  })

  $('.js-slide-takeout').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    cssEase: 'ease',
    arrows: true,
    pauseOnHover: false,
  })

  $(window).on('scroll load resize', function () {
    var scr_count = $(document).scrollTop()
    var showPoint = $(window).height()
    if (scr_count > showPoint) {
      $('.js-scroll-top').fadeIn().css('display', 'flex')
    } else {
      $('.js-scroll-top').fadeOut()
    }

    // SP TOP HEADER
    // if ($('.block-kv').size() > 0) {
    //   var kv_point = $('.block-kv').outerHeight()
    //   if (scr_count > kv_point) {
    //     $('.header').addClass('sp_active')
    //   } else {
    //     $('.header').removeClass('sp_active')
    //   }
    // }

    var target = document.getElementsByClassName('js-flowerpoint')

    for (var i = 0; i < target.length; i++) {
      var t_height = target[i].offsetHeight
      var t_classN = target[i].firstElementChild.className

      // get target-position => distance from screen-top-position
      var offsetY = target[i].getBoundingClientRect().top

      // screen-height
      var screenHeight = window.outerHeight

      // judge of target-position in or out
      var t_position = offsetY - screenHeight

      if (-screenHeight <= t_position + t_height && t_position < 0) {
        // 画面内
        if (t_classN.indexOf('fadeIn') == -1) {
          // fadeInなし
          t_classN = t_classN + ' fadeIn' // fadeInクラス追加
        } else if (t_classN.indexOf('fadeOut') !== -1) {
          t_classN = t_classN.replace(/fadeOut/g, 'fadeIn') // fadeInに置き換え
        }
      } else {
        // 画面外
        if (t_classN.indexOf('fadeOut') == -1) {
          // fadeoutなし
          t_classN = t_classN + ' fadeOut'
        } else if (t_classN.indexOf('fadeIn') !== -1) {
          t_classN = t_classN.replace(/fadeIn/g, 'fadeOut')
        }
      }
      target[i].firstElementChild.className = t_classN
    }
  })

  $('.js-scroll-top').click(function () {
    $('html,body').animate({ scrollTop: 0 }, 'linear')
    return false
  })

  $('.header-menu').click(function () {
    $(this).toggleClass('open')
    $('.header-nav').fadeToggle(500)
  })

  // ニュース用cbox
  var wsize = window.innerWidth
  var gWidth = wsize * 0.5
  var gHeight = wsize * 1.5
  if (window.matchMedia('(max-width:768px)').matches) {
    // SPの場合は比率変更
    gWidth = wsize * 0.93
    gHeight = wsize * 1.6
  }

  /*
	maxWidth: '90%',
    maxHeight: '90%',	
	*/

  $('.js-cbox-open').colorbox({
    maxWidth: '90%',
    maxHeight: '90%',
    opacity: 0.7,
  })
  /*	
	$('.js-cbox-open').colorbox({
		width: gWidth,
		height: gHeight,
		opacity: 0.5,
	});
*/

  $('a[href^=#]').click(function () {
    var href = $(this).attr('href')
    var target = $(href == '#' || href == '' ? 'html' : href)
    var position = target.offset().top

    $('.header-menu').removeClass('open')
    $('.header-nav').hide()

    $('html, body').animate({ scrollTop: position }, 550, 'swing')
    return false
  })

  $('.js-quality-thumbnail').click(function () {
    var index = $('.js-quality-thumbnail').index(this)
    const target_index = $('.active').index('.js-quality-item')

    if (index !== target_index) {
      $('.js-quality-thumbnail').removeClass('active')
      $('.js-quality-thumbnail').eq(index).addClass('active')

      $('.js-quality-item').removeClass('active').fadeOut(100)
      $('.js-quality-item').eq(index).addClass('active').fadeIn(1000)
    }
  })

  $(window).on('load resize', () => {
    const blockKvHeiht = $('.block-kv').outerHeight()

    document.documentElement.style.setProperty('--block-kv-heiht', blockKvHeiht + 'px')
  })

  $(window).on('load scroll resize', () => {
    const scrLength = $(window).scrollTop()

    if (scrLength >= 20) {
      $('.follow-me').addClass('is-reduced')

      const followMeHeiht = $('.follow-me').outerHeight()
      document.documentElement.style.setProperty('--follow-me-height', followMeHeiht + 'px')
    } else {
      $('.follow-me').removeClass('is-reduced')
    }
  })
})
